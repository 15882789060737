
@font-face {
    font-family: 'josefin';
    src: 
         url('../assets/fonts/JosefinSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'manrope';
    src: 
         url('../assets/fonts/Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'teacher';
    src:
         url('../assets/fonts/Teachers-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }